$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("visibility", "visible");
  $("body").addClass("fadeTofade");
  var domain = "https://theofficecocr.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 150) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  $(".opn-cls-orders").on("click", function (event) {
    event.stopPropagation();
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  $(".opn-cls").on("click", function () {
    $(".wrap_links").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest("nav").length &&
      $(".wrap_links").hasClass("open--cls")
    ) {
      $(".wrap_links").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");

  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false,
    });

    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }

  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  var allowedPaths = ["/home", "/", "/about"];
  var currentPath = window.location.pathname.replace(".html", "");
  if (allowedPaths.includes(currentPath)) {
    $(document).ready(function () {
      function scrollToVips() {
        var vipsElement = $(".vips");
        if (vipsElement.length > 0) {
          $("html, body").animate(
            {
              scrollTop: vipsElement.offset().top,
            },
            500
          );
        }
      }
      $("[data-scroll-to]").on("click", function () {
        scrollToVips();
      });
    });
  }

  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  if (allowedPaths.includes(currentPath)) {
    $(document).ready(function () {
      const mediaQuery = window.matchMedia("(min-width: 600px)");

      if (mediaQuery.matches) {
        const $fadersLeft = $(".fade-left");
        const $fadersRight = $(".fade-right");

        const appearOptions = {
          threshold: 0,
          rootMargin: "0px 0px -50px 0px",
        };

        const appearOnScroll = new IntersectionObserver(function (entries) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              $(entry.target).addClass("show");
            } else {
              $(entry.target).removeClass("show");
            }
          });
        }, appearOptions);

        $fadersLeft.each(function () {
          appearOnScroll.observe(this);
        });

        $fadersRight.each(function () {
          appearOnScroll.observe(this);
        });
      } else {
      }
    });
  }

  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");

  setTimeout(function () {
    if (allowedPaths.includes(currentPath)) {
      var modalFondo = document.querySelector(".modal-fondo");
      var bgImage = modalFondo.getAttribute("data-bg");

      if (bgImage) {
        modalFondo.style.backgroundImage = bgImage;
      }

      document.body.classList.add("no-scroll");
      var modal = document.querySelector(".modal");
      modal.classList.add("show_modal");
      modal.classList.add("fadeIn");

      document.querySelectorAll(".btn_modal").forEach(function (button) {
        button.addEventListener("click", function () {
          modal.classList.add("animationless");
          modal.classList.remove("fadeIn");
          document.body.classList.remove("no-scroll");
        });
      });
    }
  }, 1500);

  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  var currentDate = new Date().toISOString();
  var ogUpdatedTimeMeta = $("#ogUpdatedTime");
  if (ogUpdatedTimeMeta.length > 0) {
    ogUpdatedTimeMeta.attr("content", currentDate);
  }

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  //Location Name
  LOCATION_NAME = "the office";
  //Description
  ABOUT_HOME =
    "<span>Located at 230 Third St Ste 100 in Castle Rock, we’re your neighborhood spot for delicious food and good times. Our menu offers a variety of options, from brunch favorites like French Toast and Eggs Benedict to lunch staples like the Mahi Sandwich and Bacon Cheeseburger. Whether you're here for brunch, lunch, or happy hour, we’ve got something for everyone.</span><span>At The Office Co., we pride ourselves on creating a welcoming atmosphere where friends and family can...</span>";
  ABOUT_ABOUT =
    "<span>Located at 230 Third St Ste 100 in Castle Rock, we’re your neighborhood spot for delicious food and good times. Our menu offers a variety of options, from brunch favorites like French Toast and Eggs Benedict to lunch staples like the Mahi Sandwich and Bacon Cheeseburger. Whether you're here for brunch, lunch, or happy hour, we’ve got something for everyone.</span><span>At The Office Co., we pride ourselves on creating a welcoming atmosphere where friends and family can relax and enjoy great meals together. Stop by for our food specials, local brews, and craft cocktails — we can’t wait to serve you!</span>";
  //Address
  ADDRESS = "230 Third St Ste 100, Castle Rock, CO 80104";
  ADDRESS_URL = "https://maps.app.goo.gl/DHKG3haJUx5WYc5R9";
  //Email
  EMAIL = "mike27385@gmail.com";
  //Phone
  PHONE_NUMBER = "(303) 660-2280";
  //Hours & Days
  D1 = "Mon-Wed";
  H1 = "11:00 a.m. - 10:00 p.m.";
  D2 = "Thu-Fri";
  H2 = "11:00 a.m. - 12:00 a.m.";
  D3 = "Sat";
  H3 = "10:00 a.m. - 12:00 a.m.";
  D4 = "Sun";
  H4 = "10:00 a.m. - 10:00 p.m.";
  GOOGLE_DESK =
    "https://www.google.com/search?client=firefox-b-d&sca_esv=ce726b12b82d8816&sxsrf=ADLYWILeTA4pPG4CHY70G5Ai1NvPYK8Stw:1726270982529&q=opiniones+de+the+office+bar+%26+kitchen&uds=ADvngMjN513S-9NazjVJ3GUjmF1fvWBo-weJtKcymXsjq1lGqFI8NMMF2vtHIviDfjazrKB-x3akWcz55Qcxvx9jcfUupJBpKKVsc_srjgAQWj9tZkFU_2tKQmfPa1cnzH-sKsbA_ijXBTspnsBzNMgAD1Vlyf3KxsZB6VFmaEKuWosqsTzAY-Rx0JT-lqrlb9DhHNbqWOzbrKWWQfshzSQoDAPE_gDe--ZySNnHJR539nCdNlihV23W16R1cA-d8FAqT96sG2rLu_UqpGHA6L96if4voEKe5G3QA122FF0hSb2L9uTkrAbEgrzLlZFF_CiPvEH6-bsZe8tYZmQsX73uuVAbYwOZ5CzeeJ0R-hPBHMCvGDxYVejou-aITGwxwvNDdc_aCDT-vN9EyH_1cx8q9-VPv-B7RYjZMU_qEhVDpCHfJu4eYeiVhN03x3_RDwHYyjfjt7oHymW43EVH9zkwwXS3ZUEF7Yuolh7HA8T-UIs0kZq3MSVIRp5NVQgeZNAdTDqh5TMLx8ttD3c7SvB3PVr6VyOXiis6nLFA00EMxssU2Yf8n-M&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7k-_ixGAOjLYn0XxOBOPUYXojldETKK0Fxb3jE414feIGhCwAtf-sbVtll45o_OgWNrYFjqJp1bTkRr7uckFPunRtBZHIwG5J4m44sjAluzlYT4chw%3D%3D&sa=X&ved=2ahUKEwiimMzFjMGIAxWdMUQIHcMrPAkQk8gLegQIGxAB&ictx=1&biw=432&bih=838&dpr=2.5";
  GOOGLE_MOB =
    "https://www.google.com/search?client=firefox-b-d&sca_esv=ce726b12b82d8816&sxsrf=ADLYWILeTA4pPG4CHY70G5Ai1NvPYK8Stw:1726270982529&q=opiniones+de+the+office+bar+%26+kitchen&uds=ADvngMjN513S-9NazjVJ3GUjmF1fvWBo-weJtKcymXsjq1lGqFI8NMMF2vtHIviDfjazrKB-x3akWcz55Qcxvx9jcfUupJBpKKVsc_srjgAQWj9tZkFU_2tKQmfPa1cnzH-sKsbA_ijXBTspnsBzNMgAD1Vlyf3KxsZB6VFmaEKuWosqsTzAY-Rx0JT-lqrlb9DhHNbqWOzbrKWWQfshzSQoDAPE_gDe--ZySNnHJR539nCdNlihV23W16R1cA-d8FAqT96sG2rLu_UqpGHA6L96if4voEKe5G3QA122FF0hSb2L9uTkrAbEgrzLlZFF_CiPvEH6-bsZe8tYZmQsX73uuVAbYwOZ5CzeeJ0R-hPBHMCvGDxYVejou-aITGwxwvNDdc_aCDT-vN9EyH_1cx8q9-VPv-B7RYjZMU_qEhVDpCHfJu4eYeiVhN03x3_RDwHYyjfjt7oHymW43EVH9zkwwXS3ZUEF7Yuolh7HA8T-UIs0kZq3MSVIRp5NVQgeZNAdTDqh5TMLx8ttD3c7SvB3PVr6VyOXiis6nLFA00EMxssU2Yf8n-M&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7k-_ixGAOjLYn0XxOBOPUYXojldETKK0Fxb3jE414feIGhCwAtf-sbVtll45o_OgWNrYFjqJp1bTkRr7uckFPunRtBZHIwG5J4m44sjAluzlYT4chw%3D%3D&sa=X&ved=2ahUKEwiimMzFjMGIAxWdMUQIHcMrPAkQk8gLegQIGxAB&ictx=1&biw=432&bih=838&dpr=2.5";
  //Orders Online
  PICK_UP = "";
  DELIVERY1 = "https://order.toasttab.com/online/the-office-co-bar-kitchen";
  DELIVERY2 = "";
  DELIVERY3 = "";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12337.203157739994!2d-104.860745!3d39.372068!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c983da41273c3%3A0xc1d9bc193c5cc4d2!2sThe%20Office%20Bar%20%26%20Kitchen!5e0!3m2!1ses!2smx!4v1726270245294!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Social Media
  FACEBOOK = "https://www.facebook.com/profile.php?id=100063792780272";
  INSTAGRAM =
    "https://www.instagram.com/theofficecocr?igsh=MWN5YjRtYzJrZXZzbw%3D%3D";
  YELP = "https://www.yelp.com/biz/the-office-bar-and-kitchen-castle-rock-2";
  TIKTOK = "";
  //Agency
  RESTAURANTLY = "https://restaurantly.io/";
  //Agency
  $(".agency").on("click", function () {
    window.open(RESTAURANTLY, "_blank");
  });
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
  //Location - Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location - Address
  $(".address").each(function () {
    $(this).text(ADDRESS);
  });
  $(".address-link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  //About Us
  $(".description_home").each(function () {
    $(this).html(ABOUT_HOME);
  });
  $(".description_about").each(function () {
    $(this).html(ABOUT_ABOUT);
  });
  //Phone
  $(".phone").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  // Email
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  //Hours
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".h1").each(function () {
    $(this).text(H1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".h2").each(function () {
    $(this).text(H2);
  });
  $(".d3").each(function () {
    $(this).text(D3);
  });
  $(".h3").each(function () {
    $(this).text(H3);
  });
  $(".d4").each(function () {
    $(this).text(D4);
  });
  $(".h4").each(function () {
    $(this).text(H4);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Description Modal
  $(".description-01").each(function () {
    $(this).html(DESC01);
  });
  $(".description-02").each(function () {
    $(this).html(DESC02);
  });
  //Google Reviews
  $(".desk-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".mob-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //3rd Party Vendors
  $(".order-pickup").each(function () {
    $(this).click(function () {
      window.open(PICK_UP, "_blank");
    });
  });
  $(".order-delivery1").each(function () {
    $(this).click(function () {
      window.open(DELIVERY1, "_blank");
    });
  });
  $(".order-delivery2").each(function () {
    $(this).click(function () {
      window.open(DELIVERY2, "_blank");
    });
  });
  $(".order-delivery3").each(function () {
    $(this).click(function () {
      window.open(DELIVERY3, "_blank");
    });
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".ig").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
  $(".tk").each(function () {
    $(this).click(function () {
      window.open(TIKTOK, "_blank");
    });
  });
});
